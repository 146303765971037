import styled from 'styled-components';
import {spacing} from '../../theme/variables';

export const StyledActionDescription = styled.div`
  padding: ${spacing.large}px 0 ${spacing.small}px;
`;

export const ActionButtonWrapper = styled.div`
  margin-top: ${spacing.medium}px;
  align-items: center;
  display: flex;
`;

export const ActionButtonDescription = styled.div`
  padding-left: ${spacing.small}px;
`;