import styled from 'styled-components';
import {spacing} from '../../theme/variables';

export const StyledVerticalMargin = styled.div`
  ${props => props.size && props.direction && props.direction === 'both' && `
    margin: ${spacing[props.size]}px 0;
  `}

  ${props => props.size && props.direction && props.direction === 'top' && `
    margin: ${spacing[props.size]}px 0 0;
  `}

  ${props => props.size && props.direction && props.direction === 'bottom' && `  
    margin: 0 0 ${spacing[props.size]}px;
  `}
`;