import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Home, Single, Terms, Payment, PaymentStatus, CatchDibsError } from './views';

import { GlobalStyle } from './theme/global';

function App() {
  return (
    <Router>
      <GlobalStyle />
        <main>
          <Switch>
            <Route exact path='/' component={CatchDibsError} />
            <Route exact path='/paymentstatus/:uuid' component={PaymentStatus} />
            <Route exact path='/admin' component={Home} />
            <Route exact path='/admin/filter/:filter' component={Home} /> 
            <Route exact path='/order/:uuid' render={(props) => <Single {...props} isAdmin={false} /> } />              
            <Route exact path='/payment/:uuid' component={Payment} />
            <Route exact path='/admin/order/:uuid' render={(props) => <Single {...props} isAdmin={true} /> } />              
            <Route exact path='/villkor' component={Terms} />
            <Route exact path='/terms' component={Terms} />
          </Switch>
        </main>
    </Router>
  );
}

export default App;
