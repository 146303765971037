import React from 'react';
import PropTypes from 'prop-types';
import {Heading} from '../';
import {StyledList } from './style';

const StrippedList = ({heading, items}) => {
  return (
      <>
      {heading &&
        <Heading size="medium">{heading}</Heading>
      }
      <StyledList>
        {items.map((item, index) => {
          return(
          <li key={index}>
            <strong>{item.key}</strong>: {item.value}
          </li>)
        })}
      </StyledList>
      </>
  );
}

StrippedList.defaultProps = {
  heading: undefined,
  items: [],
};

StrippedList.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.array,
};

export default StrippedList;