import React from 'react';
import {StyledContentWrapper} from './style';

const ContentWrapper = (props) => {
  return (
      <StyledContentWrapper>
        {props.children}
      </StyledContentWrapper>
  );
}

export default ContentWrapper;