import React from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence } from "framer-motion";
import {StyledModal, StyledOverlay} from './style';

const ModalOverlay = (props) => {
    return (
      <>
        <AnimatePresence>
          {props.visible &&
            <StyledOverlay
              key="modal"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <StyledModal>
                {props.children}
              </StyledModal>
            </StyledOverlay>
          }
        </AnimatePresence>
      </>
    );
}

ModalOverlay.defaultProps = {
  visible: false
};

ModalOverlay.propTypes = {
  visible: PropTypes.bool,
};

export default ModalOverlay;