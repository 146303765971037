import styled from 'styled-components';
import {color} from '../../theme/variables';
import {registeredStatuses} from '../../bin/config';
import { motion } from "framer-motion"

export const StyledOverlay = styled(motion.div)`
  position: fixed;
  background: rgba(255,255,255,0.9);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  
  ${(props) => props.status && props.status === registeredStatuses.paid && `
    background-color: ${color.statusPayed};
  `}
`;

export const StyledModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;