import styled from 'styled-components';
import {mq} from '../../theme/variables';

export const StyledColumnWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media(max-width: ${mq.medium -1}px){
    flex-wrap: wrap;
  }
`;

export const StyledColumn = styled.div`
  width: 100%;
  @media(min-width: ${mq.medium}px){
    width: calc(50% - 10px);
  }
`;