import styled from 'styled-components';
import {spacing, color} from '../../theme/variables';

export const StyledRadioButtonSet = styled.div`
  padding: ${spacing.small}px 0;
`;

export const StyledRadioButtonContainer = styled.span`
  padding: ${spacing.large}px 0;
`;

export const StyledRadioButtonRow = styled.div`
  display: flex;
  padding: ${spacing.xsmall}px 0;
  align-items: center;
  

  .radiobutton-container {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    height: 25px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .radiobutton-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .fake-radiobutton {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
    border: 1px solid transparent;
    transition: all 0.2s;

    &:focus{
      border: 10px solid red;
    }
  }

  input:focus + .fake-radiobutton{
    box-shadow:
        inset 0 0 0 0px transparent,
        0 0 0 3px ${color.primaryOpacity};
  }

  @media(hover:hover){
    .radiobutton-container:hover input ~ .fake-radiobutton {
      background-color: #ccc;
    }
  }
    
  .fake-radiobutton:after {
    content: "";
    position: absolute;
    opacity: 0;
    transition: all 0.2s;
  }

  .radiobutton-container input:checked ~ .fake-radiobutton:after {
    opacity: 1;
  }

  .radiobutton-container .fake-radiobutton:after {
    top: 4px;
    left: 4px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #D22429;
  }
  /* 

  .dispensform__has-error .dispensform__radiobutton{
    border: 1px solid #D22429;
  }

  .dispensform__has-error .dispensform__error{
    display: block !important;
  } */
`;