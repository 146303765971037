import styled from 'styled-components';

export const StyledLoading = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 30vh;
  align-items: center;
  padding: 40px;

  .heading{
    width: 100%;
    text-align: center;
  }
`;

export const StyledLoadingImage = styled.img`
  width: 100px;
  display: block;
  margin: 0 auto;
`;