import React, { useState, useEffect } from 'react';
import {Page, ContentWrapper, ListEntries, VerticalMargin, Heading, Loading} from '../../components';
import { apiPromise } from '../../utils/helpers/api';
import {registeredStatuses} from '../../bin/config';
import { useLocation } from 'react-router-dom';

const Home = props => {
  const location = useLocation();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  function fetchCases(){
    const endpoint = (props.match.params.filter === 'archived') ? 'archived/orders' : 'orders';
    return apiPromise({
      endpoint: endpoint,
      method: 'GET',
    });
  }

  function fetchAndSetOrders(){
    setLoading(true);
    setItems([]);
    fetchCases()
    .then((response) => {
      let tempItems = response.data;
      
      switch (props.match.params.filter) {
        case registeredStatuses.pending:
          tempItems = response.data.filter(item => {
            return item.status === registeredStatuses.pending;
          });
          break;
        case registeredStatuses.approved:
          tempItems = response.data.filter(item => {
            return item.status === registeredStatuses.approved;
          });
          break;
        case registeredStatuses.paid:
          tempItems = response.data.filter(item => {
            return item.status === registeredStatuses.paid;
          });
          break;
        case registeredStatuses.rejected:
          tempItems = response.data.filter(item => {
            return item.status === registeredStatuses.rejected;
          });
          break;
        default:
          break;
      }
      setItems(tempItems.reverse());
      
      setLoading(false);
    }).catch((error) =>{
      console.log(error);
    } );
  }

  useEffect(()=>{
   fetchAndSetOrders();
  }, []);

  useEffect(()=>{
    fetchAndSetOrders();
   }, [location]);

  return (
      <Page isAdmin filter={props.match.params.filter || 'all'}>
        <ContentWrapper>
          <VerticalMargin size="medium">
            {loading &&
              <Loading message="Hämtar ansökningar..." />
            }

            {!loading && items.length === 0 &&
              <Heading size="small">Det finns inga matchande ansökningar för tillfället</Heading>
            }
            <ListEntries items={items} />
          </VerticalMargin>
        </ContentWrapper>
      </Page>
  );
}

export default Home;