import styled from 'styled-components';
import {color, font, spacing} from '../../theme/variables';
import {registeredStatuses} from '../../bin/config';

export const StyledStatus = styled.div`
  background: ${color.statusPending};
  border-radius: 50px;
  padding: 3px 5px;
  width: ${spacing.statusboxWidth}px;
  text-align: center;
  font-size: ${font.size.body.small};
  font-weight: ${font.weight.bold};

  ${(props) => props.status && props.status === registeredStatuses.approved && `
    background-color: ${color.statusApproved};
  `}

  ${(props) => props.status && props.status === registeredStatuses.paid && `
    background-color: ${color.statusPayed};
  `}
`;