import React from 'react';
import PropTypes from 'prop-types';
import {StyledVerticalMargin} from './style';

const VerticalMargin = (props) => {
  return (
    <StyledVerticalMargin {...props}>
      {props.children}
    </StyledVerticalMargin>
  );
}

VerticalMargin.defaultProps = {
  size: 'medium',
  direction: 'both',
};

VerticalMargin.propTypes = {
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge']),
  direction: PropTypes.oneOf(['both', 'top', 'bottom']),
};

export default VerticalMargin;