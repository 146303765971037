import React from 'react';
import PropTypes from 'prop-types';
import {StyledActionDescription, ActionButtonWrapper, ActionButtonDescription} from './style';
import {Button} from '../';
import {acceptedStatuses, price, registeredStatuses} from '../../bin/config';

const ActionDescription = ({status, number_of_days, archived, uuid}) => {
  if(archived){
    if(registeredStatuses.pending || registeredStatuses.approved){
      return(
        <StyledActionDescription>
          Ansökan är avbruten.
        </StyledActionDescription>
      )
    } else{
      return(
        <StyledActionDescription>
          Ansökan är arkiverad.
        </StyledActionDescription>
      )
    }
  } else if(status === registeredStatuses.pending){
    return (
      <StyledActionDescription>
        Ansökan inväntar granskning.
      </StyledActionDescription>
    );
  }

  if(status === registeredStatuses.approved){
    return (
      <StyledActionDescription>
        Din ansökan är godkänd och redo att betalas.
        <ActionButtonWrapper>
          <div><Button route={`/payment/${uuid}`}>Betala</Button></div>
          <ActionButtonDescription>
            Summa att betala: {price[number_of_days]} kr inkl moms.<br />
            Betalning sker säkert med Dibs.
          </ActionButtonDescription>
        </ActionButtonWrapper>
      </StyledActionDescription>
    );
  }

  if(status === registeredStatuses.paid){
    return (
      <StyledActionDescription>
        Din ansökan är godkänd och betald.
      </StyledActionDescription>
    );
  }

  if(status === registeredStatuses.rejected){
    return (
      <StyledActionDescription>
        Din ansökan godkändes inte.
      </StyledActionDescription>
    );
  }

  return null;
}

ActionDescription.defaultProps = {

};

ActionDescription.propTypes = {
  number_of_days: PropTypes.number.isRequired,
  status: PropTypes.oneOf(acceptedStatuses).isRequired,
  uuid: PropTypes.string.isRequired,
};

export default ActionDescription;