import React from 'react';
import {StyledLoading, StyledLoadingImage} from './style';
import {Heading} from '../';
import LoadingImage from '../../assets/spinnericon.gif';

const Loading = (props) => {
  return(
    <StyledLoading>
      <StyledLoadingImage src={LoadingImage} />
      {props.message && 
        <Heading size="medium">{props.message}</Heading>
      }
    </StyledLoading>
  )
}

export default Loading;