import styled from 'styled-components';
import {spacing} from '../../theme/variables';

export const StyledButtonGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ${(props) => props.align && props.align === 'center' && `
    justify-content: center;
  `}

  button, a{
    margin-right: ${spacing.small}px;
  }
`;