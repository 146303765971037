import styled from 'styled-components';
import {spacing, mq} from '../../theme/variables';

export const StyledContentWrapper = styled.div`
  padding: 0 ${spacing.bodypadding}px;
  
  @media(min-width: ${mq.navbreak}px){
    padding: 0 ${spacing.bodypaddingLarge}px;
  }
  
  @media(min-width: ${mq.xlarge}px){
    padding: 0 ${spacing.large}px;
  }
`;