// Production
const apiUrl = 'https://shielded-ridge-56527.herokuapp.com/https://dispenser-api.brannovagforening.se/api';
const baseUrl = 'https://dispenser.brannovagforening.se';

// Development
// const apiUrl = (process.env.NODE_ENV === 'development') ? 'https://shielded-ridge-56527.herokuapp.com/https://branno-dispenser-api.fullystudios.se/api' : 'https://shielded-ridge-56527.herokuapp.com/https://branno-dispenser-api.fullystudios.se/api' ;
// const baseUrl = 'https://branno-dispenser.fullystudios.se';

const price = {
  3: 500,
  30: 1000,
};

const acceptedStatuses = ['pending', 'approved', 'paid', 'rejected'];

const registeredStatuses = {
  pending: 'pending',
  approved: 'approved',
  paid: 'paid',
  rejected: 'rejected',
};

const contactEmail = 'dispens@brannovagforening.se';

module.exports = {
  apiUrl, price, acceptedStatuses, registeredStatuses, contactEmail, baseUrl,
};
