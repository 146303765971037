import React from 'react';
import PropTypes from 'prop-types';
import {StyledTextArea} from './style';
import { InputLabel } from '../';

const TextArea = (props) => {
  return (
      <>
        {props.label &&
          <InputLabel>{props.label}</InputLabel>
        }
        <StyledTextArea onChange={e => props.onChange(e.target.value)}>
        {props.defaultValue}
        </StyledTextArea>
      </>
  );
}

TextArea.defaultProps = {
  label: undefined,
  defaultValue: undefined
};

TextArea.propTypes = {
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default TextArea;