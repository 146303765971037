import React from 'react';
import PropTypes from 'prop-types';
import {StyledList, StyledEntry, TextWrapper, StatusWrapper} from './style';
import {Heading, StrippedList, StatusBox, Button} from '../';

const ListEntries = ({items}) => {
  return (
    <StyledList>
      {items.map((item, index) => {
        const strippedListItems = [];
        const name = item.company ? `${item.applicant_name} (${item.company})` : item.applicant_name;
        
        if(item.created_at) {
          strippedListItems.push({
            key: 'Inskickat',
            value: item.created_at,
          })
        }

        if(item.start_date && item.end_date) {
          strippedListItems.push({
            key: 'Giltig',
            value: `${item.start_date} – ${item.end_date} (${item.number_of_days} dagar)`,
          })
        }

        if(item.reason) {
          strippedListItems.push({
            key: 'Ändamål',
            value: item.reason,
          })
        }

        if(item.destination) {
          strippedListItems.push({
            key: 'Färdmål',
            value: item.destination,
          })
        }

        if(item.regnr) {
          strippedListItems.push({
            key: 'Reg.nr',
            value: item.regnr,
          })
        }

        return(
        <StyledEntry key={index}>
          <StatusWrapper>
            <StatusBox status={item.status} archived={item.archived} />
          </StatusWrapper>
          <TextWrapper>
            <Heading size="medium">{name}</Heading>
            <StrippedList items={strippedListItems}/>
            <Button marginTop="medium" route={`/admin/order/${item.uuid}`}>Granska</Button>
          </TextWrapper>
        </StyledEntry>)
      })}
    </StyledList>
  );
}

ListEntries.defaultProps = {
  items: [],
};

ListEntries.propTypes = {
  items: PropTypes.array,
};

export default ListEntries;