/*

  Payment view. A lot like the single view. Feel free to merge these two or make some functions reuseable if you have time.

*/

import React, { useState } from 'react';
import {SimplePageTemplate, Loading, Heading, VerticalMargin, Button} from '../../components';
import {singleOrderApiCall} from '../../utils/helpers/api';
import {useInterval} from '../../utils/helpers/general';
import {contactEmail} from '../../bin/config';

// const queryString = require('query-string');

const PaymentStatus = props => {
  // const urlParams = queryString.parse(props.location.search);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({});
  
  useInterval(() => {
    if(!props.match.params.uuid) return;
    
    if(!order || !order.status || !order.status === 'paid'){
      singleOrderApiCall(props.match.params.uuid)
      .then((response) => {
        setOrder(response.data);
        if(response.data.status === 'paid'){
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
    }
  }, 4000);
  
  
  if(loading){
    return(
      <SimplePageTemplate isAdmin={false}>
        <Loading message="Kontrollerar betalning..." />
        <p className="center body-small full-width">Om du har problem med din order, kontakta oss på <a href={`mailto:${contactEmail}`}>{contactEmail}</a></p>
      </SimplePageTemplate>
    )
  }

  // if(urlParams.paymentFailed && urlParams.paymentFailed === 'true'){
  //   return (
  //     <SimplePageTemplate isAdmin={props.isAdmin}>
  //       <Heading size="medium">Något gick fel med din betalning</Heading>
  //       <p>Kontrollera ditt betalmedel och försök igen.</p>
  //       <VerticalMargin size="large">
  //         <Button route={`/order/${order.uuid}`}>Tillbaka till ärende</Button>
  //       </VerticalMargin>
  //     </SimplePageTemplate>
  //   );
  // }

  return (
    <SimplePageTemplate isAdmin={props.isAdmin}>
      <Heading size="medium">Betalning genomförd</Heading>
      <p>Din dispens är klar för att användas.</p>
      <VerticalMargin size="large">
        <Button route={`/order/${order.uuid}`}>Tillbaka till ärende</Button>
      </VerticalMargin>
    </SimplePageTemplate>
  );
}

export default PaymentStatus;