import styled from 'styled-components';
import {color, spacing, mq} from '../../theme/variables';

export const StyledPage = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  padding-top: 83px;

  @media(min-width: ${mq.navbreak}px){
    padding-top: 0;
    padding-left: 280px;
  }
`;

export const StyledSidebar = styled.div`
  position: fixed;
  left: 0;
  z-index: 10;
  top: 0;
  background-color: ${color.white};
  width: 100%;
  padding: ${spacing.small * 0.9}px ${spacing.bodypadding}px;
  
  @media(min-width: ${mq.navbreak}px){
    width: 280px;
    bottom: 0;
    padding: ${spacing.bodypaddingLarge}px;
  }
`;

export const StyledMainWrapper = styled.div`
  flex: 1;
  padding: 0;
  display: flex;

  @media(min-width: ${mq.navbreak}px){
    padding: ${spacing.bodypaddingLarge}px;
  }
`;

export const StyledMainContent = styled.div`
  flex: 1;
  margin: 0 auto;
  background: ${color.white};
  max-width: 980px;
  padding-bottom: ${spacing.small}px;
`;

export const Logo = styled.h1`
    display: block;
    padding: 7px 13px;
    border: 3px solid ${color.primary};
    width: 180px;
    font-size: 13px;
    line-height: 150%;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: ${color.white};
    text-shadow: 2px 2px ${color.dark};
    background: ${color.primary};
    margin: 0 auto;
    
    @media(min-width: ${mq.navbreak}px){
      width: 100%;
      font-size: 17px;
      line-height: 150%;
      padding: 9px 13px;
    }
`

export const SubHeading = styled.h2`
  display: none;

  @media(min-width: ${mq.navbreak}px){
    font-size: 1.1rem;
    text-align: center;
    margin-top: ${spacing.small}px;
    width: auto;
    display: block;
  }
`;