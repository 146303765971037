import React from 'react';
import PropTypes from 'prop-types';
import {StyledPage, SubHeading, StyledSidebar, StyledMainWrapper, StyledMainContent, Logo} from './style';
import {Nav} from '../';

const Page = props => {
  return (
    <StyledPage {...props}>
      <StyledSidebar>
        <Logo>
          Brännö vägförening
        </Logo>
        <SubHeading>Vägdispenser</SubHeading>
        <Nav filter={props.filter} isAdmin={props.isAdmin}/>
      </StyledSidebar>
      <StyledMainWrapper>
        <StyledMainContent>
          {props.children}
        </StyledMainContent>
      </StyledMainWrapper>
    </StyledPage>
);
}

Page.defaultProps = {
  isAdmin: false,
  filter: undefined,
};

Page.propTypes = {
  isAdmin: PropTypes.bool,
  filter: PropTypes.string,
};

export default Page;