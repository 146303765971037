import React from 'react';
import PropTypes from 'prop-types';
import {StyledRadioButtonSet, StyledRadioButtonRow} from './style';

function RadioButtonSet({values, name, selectedValue, onChange}){
  return (
      <StyledRadioButtonSet>
        {values.map((item, index) => {
          return(
            <StyledRadioButtonRow key={`${name}-${index}`}>
              <label className="radiobutton-container">
                <input checked={selectedValue === item.value || false} name={name} type="radio" value={item.value} onChange={() => {onChange(item.value)}} />
                <span className="fake-radiobutton" />
              <p>
                {item.title}
              </p>
              </label>
            </StyledRadioButtonRow>
          )
        })}
      </StyledRadioButtonSet>
  );
}

RadioButtonSet.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RadioButtonSet;