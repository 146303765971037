export const theme = {
  color: {
    white: '#fff',
    background: '#F2F2F2',
    neutral: '#D8D8D8',
    neutral700: '#939393',
    dark: '#333333',
    primary: '#d2232a',
    primaryOpacity: 'rgba(210, 35, 42, 0.3)',
    statusPending: '#D8D8D8',
    statusApproved: '#FFF0A3',
    statusPayed: '#AFEFD8',
  },
  font: {
    family: {
      body: 'Lato',
      heading: 'Lato',
    }, 
    weight: {
      body: 400,
      bold: 700,
      heading: 700,
    },
    size: {
      body: {
        small: '0.875rem',
        regular: '1rem',
        large: '1.1rem',
      }
    }
  },
  border: {
    radius: {
      standard: 5,
    }
  },
  spacing: {
    none: 0,
    xsmall: 7,
    small: 14,
    medium: 28,
    large: 56,
    xlarge: 112,
    bodypadding: 20,
    bodypaddingLarge: 30,
    statusboxWidth: 175,
  },
  mq: {
    xsmall: 320,
    small: 576,
    medium: 768,
    large: 1025,
    xlarge: 1400,
    navbreak: 800,
  },
  blockwidth: {
    medium: 500,
  }

}

export const color = theme.color;
export const font = theme.font;
export const spacing = theme.spacing;
export const blockwidth = theme.blockwidth;
export const mq = theme.mq;
export const border = theme.border;

export default theme;