import styled from 'styled-components';
import {color, font, spacing, mq, border} from '../../theme/variables';
import {registeredStatuses} from '../../bin/config';
import NavIcon from '../../assets/navicon.svg';

export const StyledNavContainer = styled.div`
  
`;

export const StyledNavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${spacing.bodypadding}px;
  background-image: url(${NavIcon});
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
  background-size: 100%;
  opacity: 0.9;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  outline: none;

  &:focus {
    outline: none;
    box-shadow:
    inset 0 0 0 ${props => props.color ? '0px' : border.width} ${color.primary},
    0 0 0 2px ${color.primaryOpacity};
  }

  @media(min-width: ${mq.navbreak}px){
    display: block;
  }

  @media(min-width: ${mq.navbreak}px){
    display: none;
  }
`;

export const StyledNav = styled.nav`
  @media(max-width: ${mq.navbreak - 1}px){
    position: fixed;
    width: 100vw;
    background: ${color.white};
    top: 83px;
    bottom: 0;
    left: 100vw;
    z-index: 12;
    padding: ${spacing.bodypadding}px;
    transition: all 0.3s;

    ${(props) => props.isOpen && `
      left: 0;
    `}
  }

  ul{
    list-style-type: none;
    margin: 50px 0 0;
    padding: 0;

    li{
      display: block;
      margin: ${spacing.small}px 0;

      @media(min-width: ${mq.navbreak}px){
        margin: ${spacing.xsmall}px 0;
      }

      a{
        font-weight: ${font.weight.bold};
        text-transform: uppercase;
        text-decoration: none;
        color: ${color.neutral700};
        font-size: ${font.size.body.large};

        @media(min-width: ${mq.navbreak}px){
          font-size: ${font.size.body.small};
        }

        &:focus {
          outline: none;
          box-shadow:
          inset 0 0 0 ${props => props.color ? '0px' : border.width} ${color.primary},
          0 0 0 2px ${color.primaryOpacity};
        }
      }

      &.active{
        a{
          color: ${color.dark};
        }
      }
    }
  }
  ${(props) => props.status && props.status === registeredStatuses.paid && `
    background-color: ${color.statusPayed};
  `}
`;