import React from 'react';
import {StyledButtonGroup} from './style';
import PropTypes from 'prop-types';

const ButtonGroup = ({children, align}) => {
  return (
      <StyledButtonGroup align={align}>{children}</StyledButtonGroup>
  );
}

ButtonGroup.defaultProps = {
  align: 'left'
};

ButtonGroup.propTypes = {
  align: PropTypes.oneOf(['left', 'center']),
};

export default ButtonGroup;