import React, { useState, useEffect } from 'react';
import {SimplePageTemplate, AdminDispenserForm, Button, ButtonGroup, Loading, ActionDescription, Separator, StrippedList, Heading, VerticalMargin, StatusBox, ModalOverlay} from '../../components';
import {apiPromise, singleOrderApiCall} from '../../utils/helpers/api';
import {createOrderDetails} from '../../utils/helpers/general';
import {registeredStatuses, contactEmail} from '../../bin/config';

const Single = props => {
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadError, setLoadError] = useState(false);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const modalHeading = props.isAdmin ? 'Är du säker på att du vill arkivera ärendet?' : 'Är du säker att du vill avbryta ärendet?';
  const modalButtonText = props.isAdmin ? 'Ja, arkivera ärendet' : 'Ja, avbryt ärendet';

  function fetchSingleOrder(){
    setLoading(true);
    singleOrderApiCall(props.match.params.uuid)
    .then((response) => {
      const orderData = createOrderDetails(response);
      if(!orderData) {
        throw new Error('response has no data');
      }
      setOrder(orderData);
      setLoading(false);
    }).catch((error)=> {
      console.log('error');
      setLoadError(true);
      setLoading(false);
    });
  }

  useEffect(()=>{
    fetchSingleOrder();
  }, []);

  function setOrderToArchived(){
    setModalIsVisible(false);
    setLoading(true);
    apiPromise({
      endpoint: `orders/${props.match.params.uuid}`,
      method: 'DELETE',
    }).then((response) =>{
      fetchSingleOrder();
    }).catch((error) => {
      console.log(error);
    });
  }

  // Render

  if (loadError){
    return (
      <SimplePageTemplate isAdmin={props.isAdmin}>
        <Heading size="medium">Något gick fel</Heading>
        <p>Ärendet kunde inte laddas.</p>
      </SimplePageTemplate>
    )
  }
  
  if(loading){
    return(
      <SimplePageTemplate isAdmin={props.isAdmin}>
        <Loading message="Laddar ärende..." />
      </SimplePageTemplate>
    )
  }

  return (
    <SimplePageTemplate isAdmin={props.isAdmin}>
      <Heading size="small">Ansökan om tillfällig dispens</Heading>
      <Heading size="large">{order.applicant_name}</Heading>
      <StatusBox status={order.status} archived={order.archived} />
      {!props.isAdmin &&
        <ActionDescription status={order.status} archived={props.archived} uuid={order.uuid} number_of_days={order.number_of_days} />
      }
      <VerticalMargin size="medium">
        <Separator />
      </VerticalMargin>
  
      <StrippedList
        heading="Detaljer"
        items={order.details} />

      {!props.isAdmin &&
        <>
          <VerticalMargin size="medium">
            <p className="no-bottom-margin"><strong>Information & regler:</strong></p>
              <ul className="bullet-list">
              <li><a href="http://brannovagforening.se/dispenser/villkor/">Villkor för tillfällig dispens</a></li>
              <li><a href="http://brannovagforening.se/dispenser/kartor/">Karta över Brännös vägar</a></li>
            </ul>
            <p className="no-bottom-margin"><strong>Viktigt att observera:</strong></p>
            <ul className="bullet-list">
              <li>Hastigheten begränsas till 20km/h över hela ön.</li>
              <li>Det är körförbud för genomfartstrafik i byn (Husviksvägen mellan Faggeliden och värdshuset).</li>
              <li>Fordon skall parkeras på vid fastigheten eller på arbetsplatsen.</li>
            </ul>
          </VerticalMargin>            
          <p>Vid eventuella frågor, kontakta oss på <a href={`mailto:${contactEmail}`}>{contactEmail}</a></p>               
          
          { (!order.archived || (!order.archived && order.status !== registeredStatuses.paid)) &&
            <>
            <VerticalMargin size="medium">
              <Separator />
            </VerticalMargin>
            <VerticalMargin size="medium" direction="bottom">
              <p>Vid behov kan du avbryta din ansökan.</p>
              <Button onClick={() => {setModalIsVisible(true)}}>Avbryt ansökan</Button>
            </VerticalMargin>
            </>
          }
        </>
      }
      {props.isAdmin && order.admin_note &&
        <>
          <VerticalMargin size="medium">
            <Separator />
          </VerticalMargin>
          <Heading size="medium">Egen notering</Heading>
          <p>{order.admin_note}</p>
        </>
      }
  
      {props.isAdmin && !order.archived &&
        <>
        <VerticalMargin size="medium">
          <Separator />
        </VerticalMargin>
        <AdminDispenserForm onArchive={() => {setModalIsVisible(true)}} reloadOrder={fetchSingleOrder} uuid={props.match.params.uuid} order={order} />
        </>
      }
      <ModalOverlay visible={modalIsVisible}>
        <Heading size="medium">{modalHeading}</Heading>
        <VerticalMargin>
          <ButtonGroup align="center">
            <Button onClick={() => setOrderToArchived()}>{modalButtonText}</Button>
            <Button variant="textlink" onClick={() => setModalIsVisible(false)}>Nej, gå tillbaka</Button>
          </ButtonGroup>
        </VerticalMargin>
      </ModalOverlay>
    </SimplePageTemplate>
  );
}

export default Single;