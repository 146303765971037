import styled from 'styled-components';
import {color, spacing, mq} from '../../theme/variables';

export const StyledList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const StyledEntry = styled.li`
  position: relative;
  margin: 0 0 ${spacing.medium}px;
  padding: 0px 0px ${spacing.medium}px 0;
  border-bottom: 1px solid ${color.neutral};

  @media(min-width: ${mq.navbreak}px){
    padding: 0px ${spacing.statusboxWidth + 10}px ${spacing.medium}px 0;
  }
`;

export const TextWrapper = styled.div`
  
`;

export const StatusWrapper = styled.div`
  margin-bottom: ${spacing.small}px;
  @media(min-width: ${mq.navbreak}px){
    position: absolute;
    right: 0;
    top: 0px;
  }
`;