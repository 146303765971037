import axios from "axios";
import { apiUrl } from '../../bin/config';
var qs = require('qs');

 export const apiPromise = (callData) => {
  /*
    callData = {
      endpoint,
      method,
      body
    }
  */
  // console.log(callData);
  let endpoint = (callData.endpoint) ? callData.endpoint : false; //endpoint: (string) What endpoint of the api you want to callData. ex 'user/profile'
  let method = (callData.method) ? callData.method : 'GET'; //method: (string) PUT, GET, POST
  let body = (callData.body) ? callData.body : false; //body: (object) If you need to add a body to your callData, this is where to add it

  var call = {
    url: `${apiUrl}/${endpoint}`,
    method: method,
    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
  };

  if(body){
    call.data = qs.stringify(body);
  }

  return axios(call);

};

export const singleOrderApiCall = (uuid) => {
  return apiPromise({
    endpoint: `orders/${uuid}`,
    method: 'GET',
  });
}