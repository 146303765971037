import styled from 'styled-components';
import {color} from '../../theme/variables';

export const StyledTextArea = styled.textarea`
  resize: none;
  background-color: #eee;
  border-radius: 3px;
  display: block;
  padding: 15px;
  margin: 0;
  border: 1px solid transparent;
  border-radius: 3px;
  background: #eee;
  font-family: 'Lato', sans-serif;
  font-size: 0.95em;
  color: #666;
  width: 100%;
  height: 150px;

  &:focus{
    outline: none;
    box-shadow:
        inset 0 0 0 0px transparent,
        0 0 0 3px ${color.primaryOpacity};
  }
`;