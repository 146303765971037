import React, {useRef, useEffect} from 'react';
import {price, registeredStatuses} from '../../bin/config';

export const createOrderDetails = (response) => {
  if(!response || !response.data) return false;

  const orderData = response.data;
  orderData.details = [];

  if(response.data.applicant_name){
    orderData.details.push({key: 'Namn', value: response.data.applicant_name});
  }

  if(response.data.applicant_company){
    orderData.details.push({key: 'Företag', value: response.data.applicant_company});
  }

  if(response.data.applicant_email){
    orderData.details.push({key: 'E-post', value: response.data.applicant_email});
  }

  if(response.data.applicant_phone){
    orderData.details.push({key: 'Telefonnummer', value: response.data.applicant_phone});
  }

  if(response.data.created_at){
    orderData.details.push({key: 'Ansökan inskickad', value: response.data.created_at});
  }

  if(response.data.start_date && response.data.end_date){
    orderData.details.push({key: 'Ansökt dispens mellan', value: `${response.data.start_date} och ${response.data.end_date}`});
  }

  if(response.data.vehicle_type){
    orderData.details.push({key: 'Fordonstyp', value: response.data.vehicle_type});
  }

  if(response.data.reason){
    orderData.details.push({key: 'Ändamål', value: response.data.reason});
  }

  if(response.data.destination){
    orderData.details.push({key: 'Färdmål', value: response.data.destination});
  }

  if(response.data.number_of_days){
    orderData.details.push({key: 'Pris', value: `${price[response.data.number_of_days]} kr inkl moms (${response.data.number_of_days} dagars giltighetstid)`});
  }

  if(response.data.note_to_applicant){
    orderData.details.push({key: 'Kommentar', value: response.data.note_to_applicant});
  }

  orderData.initialAction = undefined;

  if(response.data.archived){
    orderData.initialAction = 'archive';
  } else if(response.data.status === registeredStatuses.approved || response.data.status === registeredStatuses.paid){
    orderData.initialAction = 'approve';
  } else if(response.data.status === registeredStatuses.rejected){
    orderData.initialAction = 'reject';
  }

  return orderData;

}

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}