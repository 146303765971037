import React from 'react';
import PropTypes from 'prop-types';
import {ContentWrapper, Page, VerticalMargin} from '../';
import HeaderImage from '../../assets/headerimage.jpg';

const SimplePageTemplate = props => {
  return (
    <Page isAdmin={props.isAdmin}>
      <img src={HeaderImage} alt="" />
      <ContentWrapper>
        <VerticalMargin size="medium">
          {props.children}
        </VerticalMargin>
      </ContentWrapper>
    </Page>
  );
}

SimplePageTemplate.defaultProps = {
  isAdmin: false,
};

SimplePageTemplate.propTypes = {
  isAdmin: PropTypes.bool,
};

export default SimplePageTemplate;