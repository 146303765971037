import React from 'react';
import PropTypes from 'prop-types';

//For styling, check global styles in folder theme

const Heading = ({
  children, element, size,
}) => {
  const DynamicElement = element;
  const headingElement = (
    <DynamicElement className={`heading heading-${size}`}>
      {children}
    </DynamicElement>
  );

  return (
    <>{headingElement}</>
  );
};

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  element: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5']),
  size: PropTypes.oneOf(['large', 'medium', 'small', 'xsmall']),
};

Heading.defaultProps = {
  sectionTitle: false,
  element: 'h2',
  size: 'medium',
};

export default Heading;
