import React from 'react';
import {Page, ContentWrapper, Heading, VerticalMargin} from '../../components';
import HeaderImage from '../../assets/headerimage.jpg';

const Terms = props => {
  return (
      <Page>
        <img src={HeaderImage} alt="" />
        <VerticalMargin>
          <ContentWrapper>
            <Heading size="large">Användarvillkor</Heading>
            <p>Lorem ipsum</p>
          </ContentWrapper>
        </VerticalMargin>
      </Page>
  );
}

export default Terms;