import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {StyledColumn, StyledColumnWrapper} from './style';
import {Heading, RadioButtonSet, TextArea, Button, VerticalMargin, ButtonGroup  } from '../';
import { apiPromise } from '../../utils/helpers/api';
import { registeredStatuses} from '../../bin/config';

function AdminDispenserForm(props){
  const [action, setAction] = useState(props.order.initialAction);
  const [note_to_applicant, set_note_to_applicant] = useState(props.order.note_to_applicant || null);
  const [admin_note, set_admin_note] = useState(props.order.admin_note || null);
  const [formIsSubmitted, setFormIsSubmitted] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  function handleSubmit(){
    if(!props.uuid) return false;

    if(action === 'archive'){
      props.onArchive();
      return;
    }

    setButtonIsLoading(true);
    const body = {
      applicant_email: props.order.applicant_email,
      applicant_name: props.order.applicant_name,
      applicant_phone: props.order.applicant_phone,
      vehicle_type: props.order.vehicle_type,
      reason: props.order.reason,
      destination: props.order.destination,
      number_of_days: props.order.number_of_days,
      start_date: props.order.start_date,
      status: props.order.status,
      note_to_applicant: note_to_applicant,
      admin_note: admin_note,
    };

    switch (action) {
      case 'approve':
        if(props.order.status !== registeredStatuses.paid){
          body.status = registeredStatuses.approved;
        }
        break;
      case 'reject':
        if(props.order.status !== registeredStatuses.paid){
          body.status = registeredStatuses.rejected;
        }

        break;


      default:
        break;
    };

    apiPromise({endpoint: `orders/${props.uuid}`, method: 'PUT', body: body})
    .then((response) => {
      setFormIsSubmitted(true);
      setButtonIsLoading(false);
      props.reloadOrder();
    }).catch((error) => {
      setButtonIsLoading(false);
    });
  }

  if(formIsSubmitted){
    // Not in use?
    return(
      <>
        Formuläret är skickat.
      </>
    );
  }

  return (
      <>
      <Heading size="medium">Hantera ansökan</Heading>
      <RadioButtonSet
        name="action"
        selectedValue={action}
        onChange={setAction}
        values={
          [
            {
              title: 'Godkänn',
              value: 'approve',
            },
            {
              title: 'Neka',
              value: 'reject',
            },
            {
              title: 'Arkivera',
              value: 'archive',
            },
          ]
        }
      />
      <StyledColumnWrapper>
        <StyledColumn>
          <VerticalMargin>
            <TextArea defaultValue={props.order.note_to_applicant} label="Motivering - Skickas till sökande" onChange={(value) => {set_note_to_applicant(value)}}/>
          </VerticalMargin>
        </StyledColumn>
        <StyledColumn>
          <VerticalMargin>
            <TextArea defaultValue={props.order.admin_note} label="Egen notering" onChange={(value) => {set_admin_note(value)}}/>
            </VerticalMargin>
        </StyledColumn>
      </StyledColumnWrapper>
      <ButtonGroup>
        <Button loading={buttonIsLoading} onClick={() => {handleSubmit()}}>Skicka beslut</Button>
        <Button route="/admin" variant="textlink">Avbryt</Button>
      </ButtonGroup>
      </>
  );
}

AdminDispenserForm.defaultProps = {
  items: [],
  uuid: undefined,
  reloadOrder: () => {},
  onArchive: () => {},
};

AdminDispenserForm.propTypes = {
  items: PropTypes.array,
  uuid: PropTypes.string,
  reloadOrder: PropTypes.func,
  onArchive: PropTypes.func,
};

export default AdminDispenserForm;