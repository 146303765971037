/*

  Payment view. A lot like the single view. Feel free to merge these two or make some functions reuseable if you have time.

*/

import React, { useState, useEffect } from 'react';
import {SimplePageTemplate, Loading, Heading, Button} from '../../components';
import Script from 'react-load-script';
import {singleOrderApiCall} from '../../utils/helpers/api';
import {StyledDibsContainer} from './style';
import { Redirect } from "react-router-dom";
import {baseUrl} from '../../bin/config';

const Payment = props => {
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadError, setLoadError] = useState(false);
  const [paymentComplete, setPaymentComplete] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);

  const fetchSingleOrder = () => {
    setLoading(true);
    singleOrderApiCall(props.match.params.uuid)
    .then((response) => {
      if(!response || !response.data) {
        throw new Error('response has no data');
      }
      setOrder(response.data);
      setLoading(false);
    }).catch((error)=> {
      setLoadError(true);
      setLoading(false);
    });
  }

  useEffect(()=>{
    fetchSingleOrder();
  }, []);

  function initDibsIFrame(){

    var checkoutOptions = {
      // checkoutKey: "test-checkout-key-c66a90baea874f95b732bacaa5da49b9", //Fully Test
      // checkoutKey: "test-checkout-key-d497d135dfea4dda97b6551642363cf2", //Brännö Test
      checkoutKey: "live-checkout-key-41f8cf0e20cf4ae79390cb28161988fc", //Brännö Prod
      paymentId: order.payment_id, //[required] GUID without dashes
      containerId : "dibs-complete-checkout", //[optional] defaultValue: dibs-checkout-content
      language: "sv-SE",            //[optional] defaultValue: en-GB
    };

    var checkout = new window.Dibs.Checkout(checkoutOptions);

    //this is the event that the merchant should listen to redirect to the “payment-is-ok” page

    checkout.on('payment-completed', () => {setPaymentComplete(true); console.log('payment completed') });

    checkout.on('error', () => { setPaymentFailed(true); console.log('payment error') });
  }

  if (loadError){
    return (
      <SimplePageTemplate isAdmin={props.isAdmin}>
        <Heading size="medium">Något gick fel.</Heading>
        <p>Ärendet kunde inte laddas.</p>
      </SimplePageTemplate>
    )
  }

  if(loading){
    return(
      <SimplePageTemplate isAdmin={props.isAdmin}>
        <Loading message="Laddar ärende..." />
      </SimplePageTemplate>
    )
  }

  if(paymentComplete){
    return(
      <Redirect to={`/paymentstatus/${order.uuid}`} />
    )
  }

  if(paymentFailed){
    return(
      <SimplePageTemplate isAdmin={props.isAdmin}>
        <Heading size="medium">Något gick fel</Heading>
        <p>Betalningen verkar ha stött på problem.</p>
        <Button route={`/order/${order.uuid}`}>Tillbaka till ärende</Button>
      </SimplePageTemplate>
    )
  }

  return (
    <SimplePageTemplate isAdmin={props.isAdmin}>
      <Heading size="small">Ansökan om tillfällig dispenser</Heading>
      <Heading size="large">{order.applicant_name}</Heading>

      <p>Ditt ärende är redo att betalas.</p>
      <Script
        url="https://checkout.dibspayment.eu/v1/checkout.js?v=1"
        onCreate={() => {}}
        onError={() => {}}
        onLoad={initDibsIFrame}
      />
      <StyledDibsContainer id="dibs-complete-checkout"></StyledDibsContainer>
      <Button route={`/order/${order.uuid}`} variant="textlink">Avbryt</Button>
    </SimplePageTemplate>
  );
}

export default Payment;