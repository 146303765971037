import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {StyledNav, StyledNavContainer, StyledNavButton} from './style';
import { Link } from 'react-router-dom';

const Nav = (props) => {
  const [isOpen, setIsOpen] = useState(false);
    if(!props.isAdmin) return null;
    
    return (
      <StyledNavContainer>
        <StyledNavButton onClick={() => {setIsOpen(!isOpen)}}></StyledNavButton>
        <StyledNav isOpen={isOpen}>
          <ul>
            <li className={props.filter === 'all' ? 'active' : ''}><Link to="/admin/filter/all">Alla</Link></li>
            <li className={props.filter === 'pending' ? 'active' : ''}><Link to="/admin/filter/pending">Ej granskade</Link></li>
            <li className={props.filter === 'approved' ? 'active' : ''}><Link to="/admin/filter/approved">Godkända, obetalda</Link></li>
            <li className={props.filter === 'paid' ? 'active' : ''}><Link to="/admin/filter/paid">Godkända, betalda</Link></li>
            <li className={props.filter === 'rejected' ? 'active' : ''}><Link to="/admin/filter/rejected">Nekade</Link></li>
            <li className={props.filter === 'archived' ? 'active' : ''}><Link to="/admin/filter/archived">Arkiverade</Link></li>
          </ul>        
        </StyledNav>
      </StyledNavContainer>
    );
}


Nav.defaultProps = {
  filter: undefined,
  isAdmin: false,
};

Nav.propTypes = {
  filter: PropTypes.string,
  isAdmin: PropTypes.bool,
};

export default Nav;