import React from 'react';
import PropTypes from 'prop-types';
import {StyledButton, StyledLink, StyledHref} from './style';
import { Link } from 'react-router-dom';

const Button = ({disabled, loading, children, variant, href, route, onClick, marginTop}) => {


  if (route) {
    return (
      <Link to={route}>
        <StyledLink className="button" loading={loading} variant={variant} marginTop={marginTop} >
          {loading && <span className="button__loading" />}
          <span className="button__text">{children}</span>
        </StyledLink>
      </Link>
    );
  }
  else if (href) {
    return (
      <StyledHref marginTop={marginTop} className="button" href={href}>
        {loading && <span className="button__loading" />}
        <span className="button__text">{children}</span>
      </StyledHref>
    );
  }
  else {
    return (
      <StyledButton
        disabled={disabled}
        loading={loading}
        variant={variant}
        marginTop={marginTop}
        className="button"
        onClick={e => {
          onClick && onClick(e);
        }}>
        {loading && <span className="button__loading" />}
        <span className="button__text">{children}</span>
      </StyledButton>
    );
  }
}

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  download: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  variant: PropTypes.oneOf(['standard', 'textlink']),
  marginTop: PropTypes.oneOf(['none', 'xsmall', 'small', 'medium', 'large', 'xlarge']),
};

Button.defaultProps = {
  children: '',
  disabled: false,
  download: false,
  className: '',
  type: 'button',
  onClick: false,
  href: '',
  route: '',
  variant: 'standard',
  loading: undefined,
  maginTop: 'none',
};

export default Button;
