import styled, {css} from 'styled-components';
import {spacing, border, color, font} from '../../theme/variables';
import LoadingImage from '../../assets/spinner-button.gif';

const styles = css` 
    display: inline-block;
    font-weight: ${font.weight.bold};
    border: none;
    transition: all .1s;
    cursor: pointer;
    position: relative;
    outline: none;
    box-shadow:    
        inset 0 0 0 ${props => props.color ? '0px' : border.width} ${color.primary},
        0 0 0 0 rgba(255,255,255,0);
    -webkit-appearance: none;
    background: transparent;

    &:active {
        -webkit-transform: translateY(1px);
        transform: translateY(1px);
    }
    
    &:focus {
        outline: none;
        box-shadow:
        inset 0 0 0 ${props => props.color ? '0px' : border.width} ${color.primary},
        0 0 0 4px ${color.primaryOpacity};
    }

    ${(props) => props.variant && props.variant === 'standard' && `
        background-color: ${color.primary};
        color: ${color.white};
        padding: calc(${spacing.small * 0.8}px) ${spacing.medium}px;
        border-radius: ${border.radius.standard}px;
        letter-spacing: 0.03em;
    `}
    }

    ${(props) => props.marginTop && `
        margin-top: ${spacing[props.marginTop]}px;
    `}

    ${(props) => props.loading && `
        .button__text{
            opacity: 0;
        }
    `}

    .button__loading{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        background-image: url(${LoadingImage});
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 35px;
    }
`;

export const StyledButton = styled.button`${styles}`;
export const StyledLink = styled.span`${styles}`;
export const StyledHref = styled.a`${styles}`;