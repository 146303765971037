/*

  Temporary View, going live and there are problems with redirects when payment has errors

*/

import React, { useState, useEffect } from 'react';
import {SimplePageTemplate, Loading, Heading, VerticalMargin, Button} from '../../components';
import {apiPromise} from '../../utils/helpers/api';

const queryString = require('query-string');

const CatchDibsError = props => {
  const urlParams = queryString.parse(props.location.search);
  const [loading, setLoading] = useState(true);
  const [loadError, setLoadError] = useState(false);
  const [order, setOrder] = useState({});
  const errorText = "Något verkar ha gått fel med din betalning. Du kan återgå till ditt ärende och försöka igen. Om det mot förmodan har dragits pengar från ditt konto, håll koll på ditt ärendes status och se om det ändå markerats som betalt trots denna felmarkering från Dibs.";
  
  
  function findOrderByPaymentId(){
    if(!urlParams.paymentId || urlParams.paymentId.length < 5){
      return;
    }
    setLoading(true);
    apiPromise({
      endpoint: `orders`,
      method: 'GET',
    })
    .then((response) => {
      const orderObj = response.data.find(item => {
        return item.payment_id === urlParams.paymentId;
      });

      if(orderObj && orderObj.uuid){
        setOrder(orderObj);
      } else{
        throw new Error('no matching order');
      }
      setLoading(false);
    }).catch((error)=> {
      setLoadError(true);
      setLoading(false);
    });
  }

  useEffect(()=>{
    findOrderByPaymentId();
  }, []);

  if(urlParams.paymentFailed && urlParams.paymentFailed === 'true'){
    return (
      <SimplePageTemplate isAdmin={props.isAdmin}>
        <Heading size="medium">Något gick fel med din betalning</Heading>
        <p>{errorText}</p>
        {loading &&
          <Loading message="Hämtar ditt ärende..." />
        }
        {order && order.uuid &&
          <VerticalMargin size="large">
            <Button route={`/order/${order.uuid}`}>Tillbaka till ärende</Button>
          </VerticalMargin>
        }

        {loadError &&
          <VerticalMargin size="large">
            <p>Använd länken du fått i mailet från oss för att kontrollera ditt ärende.</p>
          </VerticalMargin>
        }
      </SimplePageTemplate>
    );
  }

  if(urlParams.paymentFailed && urlParams.paymentFailed === 'false'){
    return (
      <SimplePageTemplate isAdmin={props.isAdmin}>
        <Heading size="medium">Din betalning är genomförd</Heading>
        {loading &&
          <Loading message="Hämtar ditt ärende..." />
        }
        {order && order.uuid &&
          <VerticalMargin size="large">
            <Button route={`/order/${order.uuid}`}>Tillbaka till ärende</Button>
          </VerticalMargin>
        }

        {loadError &&
          <VerticalMargin size="large">
            <p>Använd länken du fått i mailet från oss för att kontrollera ditt ärende.</p>
          </VerticalMargin>
        }
      </SimplePageTemplate>
    );
  }

  return (
    <SimplePageTemplate isAdmin={props.isAdmin}>
      
    </SimplePageTemplate>
  );
}

export default CatchDibsError;