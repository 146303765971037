import React from 'react';
import PropTypes from 'prop-types';
import {StyledStatus} from './style';
import {acceptedStatuses, registeredStatuses} from '../../bin/config';

const getText = (status, archived) => {

  if(archived){
    // First we need to see if the order is archived.
    // If so, we need to see if the case should be marked as archived or cancelled

    if(status === registeredStatuses.pending || status === registeredStatuses.approved){
      return 'Avbrutet och arkiverat'
    } else if(status ===  registeredStatuses.paid){
      return 'Arkiverat (betalt)'
    } else if(status === registeredStatuses.declined){
      return 'Arkiverat (nekat)'
    }
  }

  switch (status) {
    case registeredStatuses.pending:
      return 'Ej granskad';
    case registeredStatuses.approved:
      return 'Godkänd, ej betald';
    case registeredStatuses.paid:
      return 'Godkänd och betald';
    case registeredStatuses.rejected:
      return 'Ej godkänd';

    default:
      return '';
  }
}

const StatusBox = (props) => {
  return (
    <StyledStatus status={props.status}>
      {getText(props.status, props.archived)}
    </StyledStatus>
  );
}

StatusBox.defaultProps = {
  archived: null,
};

StatusBox.propTypes = {
  status: PropTypes.oneOf(acceptedStatuses).isRequired,
  archived: PropTypes.string,
};

export default StatusBox;