import styled from 'styled-components';

export const StyledList = styled.ul`
  list-style-type: none;
  margin: 5px 0;
  padding: 0;

  li{
    display: block;
    margin-bottom: 2px;
  }
`;
